








































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  ApplicationPaymentDto,
  AuditFlowScope,
  FundDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "ApplicationPaymentList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class ApplicationPaymentList extends Vue {
  isShow = false;
  invoiceNumber = "";
  detail: ApplicationPaymentDto = {};
  typeList: any = [];
  queryForm = {
    title: "",
    fundName: "",
    fundProjectName: "",
    applicantName: "",
  };
  projectCategory: any = []; // 项目类型
  projectDomain: any = []; // 项目领域
  activityClass: any = []; // 活动分类

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentProjectCategory",
      })
      .then((res) => {
        this.projectCategory = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentProjectDomain",
      })
      .then((res) => {
        this.projectDomain = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentActivityClass",
      })
      .then((res) => {
        this.activityClass = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.applicationPayment.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "applicationPaymentCreate",
    });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.$router.push({
      name: "officeApplicationPaymentDetail",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 编辑
  handleEdit(id: number) {
    this.$router.push({
      name: "applicationPaymentEdit",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    let scope: AuditFlowScope = row.auditFlowScope;

    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope[scope],
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handleSetInvoiceNumber(row: ApplicationPaymentDto) {
    this.isShow = true;
    this.detail = row;
  }

  // 设置发票数量
  setNum() {
    if (!this.invoiceNumber) {
      this.$message({
        type: "error",
        message: "请填写号码!",
      });
      return;
    }
    api.applicationPayment
      .setInvoiceNumbers({
        body: {
          id: this.detail.id,
          invoiceNumbers: [this.invoiceNumber],
        },
      })
      .then((res: any) => {
        if (res && res.success) {
          this.isShow = false;
          this.invoiceNumber = "";
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.error.message);
        }
      });
  }
}
